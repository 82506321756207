.search-listing {
  @include margin(70px null 92px);
}

// Tabs
// -----------------------------------------------------------------------------
.search-listing-tabs {
  @include margin(null null 40px);
  display: flex;
  max-width: 805px;
  border-bottom: 1px solid rgba($brand-grey-faded, .33);

  li:first-child {
    @include margin(null null null -15px);
  }
}

.search-listing-tab {
  @include padding(10px 15px 0);
  display: block;
  transition: color .3s $ease-out-quart;
  color: $brand-black;
  font-size: rem(14px);
  line-height: 1;
  text-decoration: none;

  .tab-text {
    @include padding(null null 10px);
    display: block;
    position: relative;

    &::after {
      @include position(absolute, null null -1px 0);
      @include size(100%, 1px);
      content: '';
      display: block;
      transform: scaleX(0);
      transition: transform .3s $ease-out-quart;
      background-color: $color-secondary;
    }
  }

  &:hover {
    color: $color-secondary;
  }

  &.is-active {
    color: $color-secondary;

    .tab-text::after {
      transform: scaleX(1);
    }
  }
}

// Subtitle
// -----------------------------------------------------------------------------
.search-listing-subtitle {
  display: block;
  color: $brand-grey-faded;
  font-size: rem(16px);
  line-height: 1;
}


// List
// -----------------------------------------------------------------------------
.search-listing-list {
  @include margin(100px null 96px);
  max-width: 805px;

  @include mq($until: 'l') {
    @include margin(80px null 76px);
  }

  .search-card {
    border-top: 1px solid lighten($brand-grey-faded, 30%);

    &:last-child {
      border-bottom: 1px solid lighten($brand-grey-faded, 30%);
    }
  }
}
