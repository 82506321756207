.elementor-widget-breadcrumb {
  .elementor-widget-container {
    @include margin(null null -10px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 0;

    > span {
      @include margin(null null 10px);
    }
  }

  // Home
  // -----------------------------------------------------------------------------
  .home {
    @include margin(-2px 13px null null);
    @include size(16px);
    display: block;
    position: relative;
    background-color: $color-primary;
    background-image: image('home.png');
    background-size: 100% 100%;

    span {
      @include size(1px);
      position: absolute;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0 0 0 0);
      border: 0;
    }

    &::after {
      @include position(absolute, 2px -10px null null);
      content: '/';
      color: $brand-black;
      font-size: rem(14px);
      line-height: 1;
    }
  }

  // Links
  // -----------------------------------------------------------------------------
  a:not(.home) {
    @include margin(null 13px null null);
    display: block;
    position: relative;
    transition: color .3s $ease-out-quart;
    color: $brand-black;
    font-size: rem(14px);
    line-height: 1;
    text-decoration: none;

    // span {
      // @include line-clamp(1, 14px, false);
    // }

    &::after {
      @include position(absolute, 0 -9px null null);
      content: '/';
      color: $brand-black;
    }

    &:hover {
      color: $color-secondary;
    }
  }

  // Current
  // -----------------------------------------------------------------------------
  .post-page,
  [property="itemListElement"] > span {
    display: block;
    position: relative;
    color: $brand-grey;
    font-size: rem(14px);
    line-height: 1;
  }
}
