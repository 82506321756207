.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq($until: 'l') {
    justify-content: space-between;
  }
}

// Button
.pagination-button {
  @include size(30px, 64px);
  position: relative;
  text-decoration: none;

  @include mq($until: 'l') {
    width: 64px;
  }

  .svg {
    @include position(absolute, calc(50% - 7px) null null calc(50% - 7px));
    z-index: 1;
    transition: transform .3s $ease-out-quart;
    fill: $brand-grey-faded;
  }

  &::after {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    content: '';
    z-index: 0;
    transition: transform .3s $ease-out-quart;
    background-color: $brand-grey-light;
  }
}

a.pagination-button { // scss-lint:disable all
  .svg {
    fill: $color-secondary;
  }

  &:hover {
    &.prev {
      .svg,
      &::after {
        transform: translateX(-5px);
      }
    }

    &.next {
      .svg,
      &::after {
        transform: translateX(5px);
      }
    }
  }
}

// List
// -----------------------------------------------------------------------------
.pagination-list {
  @include margin(null 12px);
  @include padding(null 4px);
  display: flex;
  background-color: $brand-grey-light;

  @include mq($until: 'l') {
    display: none;
  }
}

.pagination-list-link {
  @include padding(22px 20px 24px);
  display: block;
  position: relative;
  background-color: $brand-grey-light;
  color: $brand-grey;
  font-size: rem(18px);
  line-height: 1;
  text-decoration: none;

  &::after {
    @include position(absolute, null null 14px calc(50% - 8px));
    @include size(16px, 2px);
    content: '';
    transform: scaleX(0);
    transition: transform .3s $ease-out-quart;
    background-color: $color-secondary;
  }

  &:hover,
  &.current {
    &::after {
      transform: scaleX(1);
    }
  }
}
