// Single service
.single-services {
  @include mq($until: 'm') {
    .post-header {
      @include margin(null -47px null -47px);
      &-wrap {
        @include margin(null 47px);
      }
    }
  }
}
