.gallery-grid-item {
  @include padding(10px);

  &-size-1 {
    width: calc(100% / 3);

    @include mq($until: 'm') {
      width: 100%;
    }

    @include mq($from: 'm') {
      figure {
        @include aspect-ratio(393, 238);
      }
    }
  }

  &-size-2 {
    width: calc(100% / 3 * 2);

    @include mq($until: 'm') {
      width: 100%;
    }

    @include mq($from: 'm') {
      figure {
        @include aspect-ratio(806, 496);
      }
    }
  }

  &-size-3 {
    width: 100%;

    figure {
      @include aspect-ratio(1220, 750);
    }
  }

  a {
    display: block;
  }

  figure {
    overflow: hidden;
  }

  img {
    display: block;
    width: 100%;

    @include mq($from: 'm') {
      @include position(absolute, 0 null null 0);
      @include object-fit(cover);
      @include size(100%);
    }
  }
}
