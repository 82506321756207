// scss-lint:disable all
// Single service
.tax-motive, .tax-sector {
  .section-image-text .image-text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .service-list-title {
    font-size: rem(40px);
    line-height: (48 / 40);
  }
}
