.elementor-widget-job-list,
.elementor-widget-events-list {
  .elementor-widget-container {
    display: flex;
    flex-wrap: wrap;
  }

  .block-heading {
    width: 100%;
  }

  .block-listing {
    flex-grow: 1;

    @include mq($until: 'l') {
      width: 100%;
    }
  }

  .block-filters {
    &:not(.aside) {
      width: 100%;

      + .block-listing {
        @include margin(15px null null);
      }
    }

    &.aside {
      flex-shrink: 0;

      @include mq($until: 'l') {
        width: 100%;

        + .block-listing {
          width: calc(100% - 273px);
        }
      }

      @include mq($from: 'l') {
        @include margin(null null null 23px);
        order: 2;
      }
    }
  }

  .pagination {
    @include margin(80px null null);
    order: 3;
    width: 100%;
  }
}
