.elementor-testimonial-item {

  &-inner {
    @include padding(null 25px);
    text-align: center;
  }

  &-image {
    @include aspect-ratio(186, 186);
    @include margin(null auto 40px auto);
    width: 186px;
    overflow: hidden;
    border-radius: 50%;

    img {
      @include position(absolute, 0 null null 0);
      @include object-fit(cover);
      min-width: 100%;
      min-height: 100%;
      transition: transform .4s $ease-out-quad;
    }
  }

  &-title {
    @include font('alternate', 'bold');
    color: $brand-black;
    font-size: rem(18px);
    line-height: rem(26px);
  }

  &-tagline {
    @include font('sans-serif', 'regular');
    display: block;
    color: $brand-grey;
    font-size: rem(14px);
    line-height: rem(16px);
  }

  &-text {
    @include margin(20px null null null);
    @include font('sans-serif', 'light');
    position: relative;
    color: $brand-black;
    font-size: rem(18px);
    line-height: rem(32px);

    strong {
      font-weight: 700;
    }

    p:first-child {
      &::before {
        @include margin(null 10px null null);
        content: '«';
        display: inline-block;
        color: $color-primary;
      }
    }

    p:last-child {
      &::after {
        @include margin(null null null 10px);
        content: '»';
        display: inline-block;
        color: $color-primary;
      }
    }
  }
}
