.menu-faitiere-card {
  position: relative;
  flex-shrink: 0;

  @include mq($until: 'l') {
    @include padding(40px null);
  }

  &:hover {
    .card-title {
      color: $color-secondary;
    }

    .svg {
      transform: translateX(5px);
    }
  }

  .svg {
    @include position(absolute, 8px 0 null null);
    transition: transform .3s $ease-out-quart;
    fill: $color-secondary;

    @include mq($until: 'l') {
      top: 48px;
    }
  }

  .card-title {
    @include padding(null 21px null null);
    @include font(alternate, bold);
    display: block;
    transition: color .3s $ease-out-quart;
    color: color(title);
    font-size: rem(24px);
    line-height: (32 / 24);
  }

  .card-desc {
    @include margin(10px null null);
    font-size: rem(18px);
    line-height: (28 / 18);
  }

  .card-link {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    z-index: 2;
  }
}
