.menu-faitiere {
  @include position(fixed, 0 null null 0);
  @include size(100%);
  display: none;
  z-index: 1001;

  .has-menu-faitiere & {
    display: block;
  }

  .container {
    @include padding(115px null null);
    position: relative;
    height: 100%;

    @include mq($until: 'l') {
      @include padding(40px null null);
    }
  }

  .menu-faitiere-card {
    width: 100%;

    @include mq($until: 'l') {
      &:nth-child(n + 2) {
        border-top: 1px solid lighten($brand-grey-faded, 20%);
      }
    }

    @include mq($from: 'l') {
      width: calc(33.33% - 80px);

      // Horizontal spacing
      &:nth-child(3n - 1) {
        @include margin(null 120px);
      }

      // Vertical spacing
      &:nth-child(n + 4) {
        @include margin(45px null null);
      }
    }
  }
}

// Wrapper
// -----------------------------------------------------------------------------
.menu-faitiere-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// Tabs
// -----------------------------------------------------------------------------
.menu-faitiere-tabs {
  @include margin(80px null null);
  display: flex;
  flex-shrink: 0;
  border-bottom: 1px solid rgba($brand-grey-faded, .33);

  @include mq($until: 'l') {
    display: none;
  }
}

.menu-faitiere-tabs-item {
  &:first-child {
    @include margin(null null null -15px);
  }
}

.menu-faitiere-tab {
  @include padding(10px 15px 0);
  display: block;
  transition: color .3s $ease-out-quart;
  outline: none;
  font-size: rem(14px);
  line-height: 1;

  .tab-text {
    @include padding(null null 10px);
    display: block;
    position: relative;

    &::after {
      @include position(absolute, null null -1px 0);
      @include size(100%, 1px);
      content: '';
      display: block;
      transform: scaleX(0);
      transition: transform .3s $ease-out-quart;
      background-color: $color-secondary;
    }
  }

  &:hover {
    color: $color-secondary;
  }

  &.is-active {
    color: $color-secondary;

    .tab-text::after {
      transform: scaleX(1);
    }
  }
}

// Select
// -----------------------------------------------------------------------------
.menu-faitiere-select-container {
  @include margin(10px null null);
  position: relative;

  @include mq($from: 'l') {
    display: none;
  }

  &::after {
    @include triangle(14px, $color-secondary, down);
    @include position(absolute, 26px 0 null null);
    content: '';
    pointer-events: none;
  }
}

.menu-faitiere-select {
  @include padding(19px 54px 18px 27px);
  @include margin(null null null -27px);
  display: block;
  width: calc(100% + 54px);
  border: 0;
  border-radius: 0;
  outline: none;
  background-color: $brand-grey-light;
  font-size: rem(14px);
  line-height: 1.4;
  appearance: none;
}

// Pannels
// -----------------------------------------------------------------------------
.menu-faitiere-pannels {
  flex-grow: 1;
  overflow: auto;

  @include mq($until: 'l') {
    @include padding(null 15px null null);
    width: calc(100% + 15px);
  }
}

.menu-faitiere-pannel {
  @include padding(40px null);
  @include size(100%, auto);
  display: flex;
  flex-wrap: wrap;

  @include mq($until: 'l') {
    @include padding(0 null null);
  }

  &:not(.is-active) {
    display: none;
  }
}

// Close
// -----------------------------------------------------------------------------
.menu-faitiere-close {
  @include position(absolute, 11px 2px null null);
  @include size(24px);
  z-index: 10;
  outline: none;

  @include mq($until: 'l') {
    top: 28px;
    right: 27px;
  }

  .svg {
    @include position(absolute, 50% null null 50%);
    transform: translate(-50%, -50%);
    transition: fill .3s $ease-out-quart;
    fill: $color-secondary;
  }

  &:hover {
    .svg {
      fill: $brand-black;
    }
  }
}

// Loader
// -----------------------------------------------------------------------------
.menu-faitiere-loader {
  @include position(absolute, 50% null null 50%);
  display: none;
  z-index: 2;
  transform: translate(-50%, -50%);

  .is-loading & {
    display: block;
  }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .loader-icon {
    @include margin(null auto);
    @include size(50px);
    display: block;
    animation: loading .8s $ease-in-out-sine infinite;
    border: 2px solid $color-white;
    border-radius: 25px;
    border-top-color: $color-secondary;
  }

  .loader-text {
    @include margin(25px null null);
    @include font(sans-serif, bold);
    display: block;
    color: $brand-black;
    font-size: rem(16px);
    line-height: 1;
    text-align: center;
  }
}

// Backdrop
// -----------------------------------------------------------------------------
.menu-faitiere-backdrop {
  @include position(absolute, 0 null null 0);
  @include size(100%);
  z-index: -1;
  background-color: $color-white;
}
