.slides-slide-item {

  .slide-inner {
    position: relative;
    transform: scale(.85);
    transition: transform .8s $ease-out-quart;
  }

  &.tns-slide-active {
    .slide-inner {
      transform: scale(1);
    }
  }

  img {
    width: 100%;
  }

  .iframe-container {
    @include aspect-ratio(1166, 613);
    @include size(100%, auto);
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.selection-slide-item,
.testimonial-slide-item {
  transition: opacity .4s $ease-out-quad;
  opacity: 0;
  pointer-events: none;

  &.tns-slide-active {
    opacity: 1;
    pointer-events: all;
  }
}
