.selection-slider {
  position: relative;

  &-controls {
    position: absolute;
    top: 50%;
    width: 100%;

    button {
      @include padding(0);
      width: auto;
      height: auto;
      border: 0;
    }

    &-prev {
      position: absolute;
      right: calc(100% + 20px);
    }

    &-next {
      position: absolute;
      left: calc(100% + 20px);
    }
  }
}
