$search-mq: 859px;

.search-card {
  @include padding(40px null);
  position: relative;

  &:hover {
    .search-card-picture {
      img {
        transform: scale(1.1);
      }
    }

    .search-card-title {
      color: $color-secondary;
    }
  }
}

// Wrapper
// -----------------------------------------------------------------------------
.search-card-wrapper {
  display: flex;
  align-items: flex-start;

  @include mq($until: 'm') {
    flex-direction: column;
  }
}

// Image
// -----------------------------------------------------------------------------
.search-card-picture {
  @include aspect-ratio(290, 190);
  @include margin(null 20px null null);
  flex-shrink: 0;
  width: 290px;
  overflow: hidden;

  @include mq($until: 'm') {
    @include margin(null 0 24px null);
    width: 100%;
  }

  img {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    @include object-fit(cover);
    transition: transform .4s $ease-out-quad;
  }
}

// Content
// -----------------------------------------------------------------------------
.search-card-content {
  flex-grow: 1;

  @include mq($from: 'm') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.search-card-site {
  @include margin(null null 23px);
  display: block;
  color: $brand-grey;
  font-size: rem(14px);
  line-height: 1.2;

  @include mq($from: 'm') {
    ~ .search-card-text {
      @include line-clamp(2, 28px, false);
    }
  }

  a {
    position: relative;
    z-index: 5;
    color: $color-secondary;
  }
}

.search-card-headline {
  @include margin(null null 14px);
  @include font(sans-serif, bold);
  display: block;
  color: $color-black;
  font-size: rem(12px);
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase;
}

.search-card-title {
  @include font(alternate, bold);
  transition: color .3s $ease-out-quart;
  border-bottom: 2px solid $color-secondary;
  color: $brand-black;
  font-size: rem(24px);
  line-height: (32 / 24);

  @include mq($from: 'm') {
    @include line-clamp(1, 32px, false);
  }

  @include mq($until: 'm') {
    display: inline;
  }
}

.search-card-text {
  @include line-clamp(3, 28px, false);
  @include margin(11px null null);
  color: $brand-grey;
  font-size: rem(18px);
  line-height: (28 / 18);

  @include mq($until: 'm') {
    @include line-clamp(4, 28px, false);
  }
}

.search-card-date {
  @include margin(22px null null);
  display: block;
  color: $brand-grey-faded;
  font-size: rem(14px);
  line-height: 1;

  @include mq($until: 'm') {
    @include margin(18px null null);
  }
}


// Link
// -----------------------------------------------------------------------------
.search-card-link {
  @include position(absolute, 0 null null 0);
  @include size(100%);
  z-index: 1;
}
