.site-header {
  @include mq($from: 'l') {
    position: relative;
    z-index: 100;
  }

  .has-menu & {
    @include mq($until: 'l') {
      @include position(fixed, 0 null null 0);
      @include size(100%, auto);
      z-index: 999;
    }
  }
}

// Top
// -----------------------------------------------------------------------------
.header-top {
  @include padding(14px null);
  display: flex;
  justify-content: flex-start;

  @include mq($until: 'l') {
    align-items: center;
    min-height: 80px;
  }
}

.header-logo-mobile {

  @include mq($from: 'l') {
    display: none;
  }

  img {
    @include size(auto);
    display: block;
    max-width: 130px;
    max-height: 52px;
  }
}

.header-faitiere-trigger {
  @include font(sans-serif, bold);
  @include padding(0);
  border: 0;
  border-radius: 0;
  outline: none;
  background: none;
  color: $brand-black;
  font-size: rem(14px);
  line-height: 1;
  cursor: pointer;
  appearance: none;

  @include mq($until: 'l') {
    display: none;
  }
}

.header-search-trigger {
  @include margin(null null null auto);
  @include padding(0);
  border: 0;
  border-radius: 0;
  outline: none;
  background: none;
  cursor: pointer;
  appearance: none;

  .svg {
    display: block;
    transition: fill .3s $ease-out-quart;
    fill: $color-secondary;
  }

  &:hover {
    .svg {
      fill: $brand-black;
    }
  }
}

.header-burger {
  @include margin(null null null 30px);
  @include size(24px);
  position: relative;
  z-index: 1000;
  outline: none;

  @include mq($from: 'l') {
    display: none;
  }

  .line {
    @include position(absolute, null null null calc(50% - 10px));
    @include size(20px, 2px);
    display: block;
    border-radius: 2px;
    background-color: $color-secondary;

    &.line-1 {
      top: calc(50% - 7px);
      transition: transform .2s .15s $ease-out-quart, opacity .1s .15s $ease-out-quart;
    }

    &.line-2 {
      top: calc(50% - 1px);
      transition: transform .2s $ease-out-quart;
    }

    &.line-3 {
      top: calc(50% - 1px);
      transition: transform .2s $ease-out-quart;
    }

    &.line-4 {
      top: calc(50% + 5px);
      transition: transform .2s .15s $ease-out-quart, opacity .1s .15s $ease-out-quart;
    }

    .has-menu & {
      &.line-1 {
        transform: translateY(6px);
        transition: transform .2s $ease-out-quart, opacity .1s .15s $ease-out-quart;
        opacity: 0;
      }

      &.line-2 {
        transform: rotate(45deg);
        transition: transform .2s .15s $ease-out-quart;
      }

      &.line-3 {
        transform: rotate(-45deg);
        transition: transform .2s .15s $ease-out-quart;
      }

      &.line-4 {
        transform: translateY(-6px);
        transition: transform .2s $ease-out-quart, opacity .1s .15s $ease-out-quart;
        opacity: 0;
      }
    }
  }
}

// Bottom
// -----------------------------------------------------------------------------
.header-bottom {
  @include padding(11px null);
  display: flex;
  align-items: center;
  min-height: 112px;
  border-top: 1px solid $brand-black;

  @include mq($until: 'l') {
    @include position(fixed, 0 null null 0);
    @include padding(80px 27px 0);
    @include size(100%);
    display: flex;
    z-index: 999;
    flex-direction: column;
    align-items: flex-start;
    transition: opacity .4s $ease-out-quart;
    border-top: 0;
    opacity: 0;
    background-color: $color-white;
    pointer-events: none;

    .has-menu & {
      opacity: 1;
      pointer-events: all;
    }
  }
}

// Logo
// -----------------------------------------------------------------------------
.header-logo {
  @include mq($until: 'l') {
    display: none;
  }

  img {
    @include size(auto);
    display: block;
    max-width: 207px;
    max-height: 88px;
  }
}

// Nav
// -----------------------------------------------------------------------------
.header-nav {
  @include padding(null 12px null null);
  margin-left: auto;

  @include mq($until: 'l') {
    @include padding(null 0 unll null);
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0;
  }
}

.header-nav-ul {
  display: flex;

  @include mq($until: 'l') {
    @include padding(15px 15px 50px null);
    flex-direction: column;
    flex-grow: 1;
    width: calc(100% + 15px);
    overflow: auto;
  }
}

.header-nav-item {
  position: relative;

  @include mq($until: 'l') {
    + .header-nav-item {
      @include margin(10px null null);
    }
  }

  @include mq($from: 'l') {
    &:last-child {
      .header-nav-submenu {
        right: 16px;
        left: auto;
      }
    }

    &:hover {
      z-index: 1;

      .header-nav-submenu {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.header-nav-link {
  @include padding(10px 16px);
  @include font(alternate, semibold);
  display: block;
  color: $brand-black;
  font-size: rem(16px);
  line-height: 1.2;
  text-decoration: none;

  @include mq($until: 'l') {
    @include font(alternate, bold);
    @include padding(null 0);
    font-size: rem(32px);
    line-height: (36 / 32);
  }

  .text {
    position: relative;

    @include mq($until: 'l') {
      .has-submenu & {
        max-width: calc(100% - 25px);
      }
    }

    &::after {
      @include position(absolute, null null -10px 0);
      @include size(100%, 2px);
      content: '';
      display: inline-block;
      transform: scaleX(0);
      transform-origin: 50% 50%;
      transition: transform .3s $ease-out-quart;
      background-color: $color-secondary;

      @include mq($until: 'l') {
        content: none;
      }
    }
  }

  &.is-active,
  &:hover {
    @include mq($until: 'l') {
      color: $color-secondary;
    }

    @include mq($from: 'l') {
      .text::after {
        transform: scaleX(1);
      }
    }
  }
}

.header-nav-plus {
  @include position(absolute, 0 -6px null null);
  @include size(32px, 100%);
  z-index: 1;

  @include mq($from: 'l') {
    display: none;
  }

  &::before,
  &::after {
    content: '';
    border-radius: 2px;
    background-color: $color-secondary;
  }

  &::before {
    @include position(absolute, 16px 15px null null);
    @include size(2px, 20px);
    transition: transform .3s $ease-out-quart;

    .submenu-open & {
      transform: rotate(90deg);
    }
  }

  &::after {
    @include position(absolute, 25px 6px null null);
    @include size(20px, 2px);
    transition: opacity .3s $ease-out-quart, transform .3s $ease-out-quart;

    .submenu-open & {
      transform: rotate(90deg);
      opacity: 0;
    }
  }
}

.header-nav-submenu {
  @include position(absolute, 100% null null 16px);
  @include padding(10px null);
  @include size(190px, auto);
  transition: opacity .4s $ease-out-quart;
  opacity: 0;
  background-color: $color-white;
  box-shadow: 0 15px 15px 0 rgba($brand-black, .18);
  pointer-events: none;

  @include mq($until: 'l') {
    @include margin(null null null 36px);
    @include padding(5px null);
    @include size(100%, auto);
    display: none;
    position: static;
    opacity: 1;
    box-shadow: none;
    pointer-events: all;

    .submenu-open & {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .submenu-item {
    @include mq($until: 'l') {
      + .submenu-item {
        @include margin(10px null null);
      }
    }
  }

  .submenu-link {
    @include padding(10px 20px);
    @include font(alternate, semibold);
    display: block;
    transition: color .3s $ease-out-quart;
    color: $brand-black;
    font-size: rem(16px);
    line-height: 1.2;
    text-decoration: none;

    @include mq($until: 'l') {
      @include padding(15px null);
      @include font(sans-serif, bold);
      font-size: rem(16px);
      line-height: (19 / 16);
    }

    &:hover,
    &.is-active {
      color: $color-secondary;
    }
  }
}

.header-nav-faitiere {
  @include margin(20px null null);
  border-top: 1px solid lighten($brand-grey-faded, 20%);

  @include mq($from: 'l') {
    display: none;
  }
}

.header-nav-faitiere-button {
  @include font(sans-serif, bold);
  @include padding(30px 0 20px);
  outline: none;
  color: $brand-black;
  font-size: rem(14px);
  line-height: 1;
}


// Lang
// -----------------------------------------------------------------------------
.header-lang {
  @include padding(null null null 12px);
  position: relative;

  @include mq($until: 'l') {
    @include position(fixed, 22px null null 27px);
    @include padding(null null null 0);
  }

  &::before {
    @include position(absolute, 50% null null 0);
    @include size(1px, 19px);
    transform: translateY(-50%);
    background-color: $brand-black;

    @include mq($from: 'l') {
      content: '';
    }
  }

  &:hover {
    z-index: 1;

    .header-lang-dropdown {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.header-lang-trigger {
  @include padding(10px null 10px 16px);
  @include font(sans-serif, bold);
  border: 0;
  border-radius: 0;
  outline: none;
  background: none;
  color: $brand-black;
  font-size: rem(14px);
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none;

  @include mq($until: 'l') {
    @include padding(null 0);
  }

  &::after {
    @include triangle(10px, $color-secondary, 'down');
    @include margin(null null null 8px);
    content: '';
    display: inline-block;
    vertical-align: middle;
  }
}

.header-lang-dropdown {
  @include position(absolute, 100% 0 null null);
  @include padding(10px null);
  @include size(70px, auto);
  transition: opacity .4s $ease-out-quart;
  opacity: 0;
  background-color: $color-white;
  box-shadow: 0 15px 15px 0 rgba($brand-black, .15);
  pointer-events: none;

  @include mq($until: 'l') {
    left: 0;
  }
}

.header-lang-link {
  @include padding(10px 20px);
  @include font(alternate, semibold);
  display: block;
  transition: color .3s $ease-out-quart;
  color: $brand-black;
  font-size: rem(16px);
  line-height: 1.2;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: $color-secondary;
  }
}
