// scss-lint:disable all
html body .site-container .elementor-widget-container {
  form.elementor-form {
    max-width: 910px;

    .elementor-form-fields-wrapper {
      @include padding(0);
      @include margin(0 -10px);
      display: flex;
      flex-wrap: wrap;
    }

    // Group / Column
    // -----------------------------------------------------------------------------
    .elementor-field-group {
      @include padding(null 0);
      @include margin(null null 0);
      display: block;
    }

    .elementor-column {
      @include margin(null null 20px);
      @include padding(null 10px);
      flex-shrink: 0;
    }

    // Label
    // -----------------------------------------------------------------------------
    .elementor-field-label {
      @include font(sans-serif, medium);
      @include margin(null null 10px);
      display: block;
      color: $brand-black;
      font-size: rem(16px);
      line-height: (19 / 16);
    }

    // Input
    // -----------------------------------------------------------------------------
    input[type="tel"],
    input[type="url"],
    input[type="text"],
    input[type="date"],
    input[type="email"],
    input[type="number"],
    select,
    textarea {
      @include padding(19px 22px);
      @include font(sans-serif, regular);
      display: block;
      width: 100%;
      max-height: 56px;
      border: 1px solid $brand-grey-clear;
      border-radius: 2px;
      font-size: rem(14px);
      line-height: (16 / 14);

      &:focus {
        border-color: $brand-black;
        box-shadow: none;
      }
    }

    // Select
    .elementor-select-wrapper {
      &::after {
        @include triangle(12px, $color-primary, down);
        @include position(absolute, calc(50% - 2px) 23px null null);
        content: '';
      }
    }

    select {
      position: relative;
      cursor: pointer;
    }

    // Textarea
    textarea {
      height: 156px;
      max-height: none;
      resize: none;
    }

    // Date
    .elementor-field-type-date {
      position: relative;

      &::after {
        @include position(absolute, 45px null null 30px);
        @include size(24px);
        content: '';
        transition: background-color .3s $ease-out-quart;
        background-color: $brand-grey-clear;
        background-image: image('form/calendar.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      input[type="date"] {
        @include padding(null null null 64px);
      }

      &.has-focus {
        &:after {
          background-color: $color-primary;
        }
      }
    }

    // Upload
    .elementor-field-type-upload {
      &:not(:first-child) {
        @include margin(10px null null);
      }

      label {
        @include font(sans-serif, bold);
        @include padding(14px 51px 14px 65px);
        @include margin(null null 0);
        display: inline-block;
        position: relative;
        border: 2px solid $color-secondary;
        border-radius: 26px 26px 0;
        background-color: rgba($color-white, 0);
        color: $color-secondary;
        font-size: rem(16px);
        line-height: 1;
        text-decoration: none;

        &::before {
          @include position(absolute, calc(50% - 10px) null null 23px);
          @include size(20px);
          content: '';
          display: inline-block;
          vertical-align: center;
          background-color: $color-secondary;
          background-image: image('form/upload.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }

      input[type="file"] {
        display: none;
      }
    }

    // Checkbox / radio
    .elementor-field-type-radio,
    .elementor-field-type-checkbox,
    .elementor-field-type-acceptance {
      @include margin(null null 30px);

      .elementor-field-subgroup {
        @include margin(null -8px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &.elementor-subgroup-inline {
          flex-direction: row;
          flex-wrap: wrap;
        }
      }

      .elementor-field-option {
        @include padding(null 8px);
      }

      input[type="radio"],
      input[type="checkbox"] {
        display: none;

        // Default state
        + label {
          @include padding(7px null 7px 24px);
          display: block;;
          position: relative;
          color: $brand-black;
          font-size: rem(14px);
          line-height: (16 / 14);
          cursor: pointer;
  
          &::before {
            @include position(absolute, 7px null null 0);
            @include size(16px);
            content: '';
            transition: border-color .3s $ease-out-quart;
            border: 1px solid $brand-grey-clear;
          }
  
          &::after {
            @include position(absolute, 9px null null 2px);
            @include size(12px);
            content: '';
            transform: scale(.5);
            transition: opacity .3s $ease-out-quart, transform .3s $ease-out-quart;
            opacity: 0;
            background-color: $color-secondary;
          }

          &:hover {
            &::before {
              border-color: $color-secondary;
            }
          }
        }

        // checked state
        &:checked {
          + label {
            &::before {
              border-color: $color-secondary;
            }

            &::after {
              transform: scale(1);
              opacity: 1;
            }
          }
        }
      }

      input[type="radio"] {
        + label {
          &::before,
          &::after {
            border-radius: 8px;
          }
        }
      }
    }

    // Submit
    .elementor-field-type-submit {
      @include margin(16px null null);
    }

    button[type="submit"].elementor-button {
      @include font(sans-serif, bold);
      @include padding(12px 51px 11px);
      display: inline-block;
      transition: opacity .3s $ease-out-quart;
      border: 2px solid $color-secondary;
      border-radius: 26px 26px 0;
      background-color: $color-secondary;
      color: $color-white;
      font-size: rem(16px);
      line-height: (21 / 16);
      text-decoration: none;

      span {
        display: block;
        line-height: (21 / 16);
      }

      &:hover {
        opacity: .7;
      }
    }

    // Errors
    // -----------------------------------------------------------------------------
    .elementor-field-group.elementor-error {
      input[type="tel"],
      input[type="url"],
      input[type="text"],
      input[type="date"],
      input[type="email"],
      input[type="number"],
      select,
      textarea {
        border-color: color(error);
      }
    }

    // Messages
    // -----------------------------------------------------------------------------
    // Inline
    .elementor-help-inline {
      @include margin(12px 0 0);
      display: block;
      position: relative;
      color: $brand-grey;
      font-size: rem(12px);
      line-height: (14 /12);

      &.elementor-message-success {
        color: color(success);
      }

      &.elementor-message-danger {
        @include padding(0 0 0 25px);
        position: relative;
        color: color(error);

        &::before {
          @include position(absolute, -2px null null 0);
          @include size(16px);
          content: '';
          background-image: image('form/error-inline.png');
          background-size: 100% 100%;
        }
      }
    }

    // Global
    .elementor-message:not(.elementor-help-inline) {
      @include padding(18px 24px 17px 76px);
      position: relative;
      width: calc(66.66% - 10px);
      border-radius: 0 0 40px 0;
      color: $brand-grey;
      font-size: rem(18px);
      line-height: (28 / 18);

      &::before {
        @include position(absolute, 16px null null 19px);
        @include size(32px);
        content: '';
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      &.elementor-message-success {
        border: 2px solid color(success);
        background-color: rgba(color(success), .1);

        &::before {
          background-image: image('form/success.png');
        }
      }

      &.elementor-message-danger {
        border: 2px solid color(error);
        background-color: rgba(color(error), .1);

        &::before {
          background-image: image('form/error.png');
        }
      }
    }
  }
}
