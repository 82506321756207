.cards {
  @include margin(null null -80px);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include mq($until: 'm') {
    @include margin(null null -40px);
    flex-direction: column;
  }

  @include mq($from: 'm') {
    &[data-cols="2"] {
      .card {
        width: calc(50% - 11px);

        &:nth-child(even) {
          @include margin(null null null 22px);
        }
      }
    }

    &[data-cols="3"] {
      .card {
        width: calc(33.33% - 15px);

        &:nth-child(3n - 1) {
          @include margin(null 22px);
        }
      }
    }

    &[data-cols="4"] {
      .card {
        width: calc(25% - 22px);

        &:nth-child(4n),
        &:nth-child(4n - 1),
        &:nth-child(4n - 2) {
          @include margin(null null null 22px);
        }
      }
    }
  }

  .card {
    @include margin(null null 80px);
    width: 100%;

    @include mq($until: 'm') {
      @include margin(null null 40px);
    }
  }

  + .pagination {
    @include margin(90px null null);
  }
}
