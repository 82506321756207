.block-heading {
  @include padding(null null 60px);
  display: flex;
  justify-content: space-between;

  @include mq($until: 'l') {
    @include padding(null null 30px);
    flex-direction: column;
  }

  &-right {
    flex-shrink: 0;
  }

  &-title {
    @include padding(null null 20px);
    @include font('alternate', 'bold');
    color: $color-black;
    font-size: rem(40px);
    line-height: rem(44px);
  }

  &-description {
    @include font('sans-serif', 'regular');
    color: $brand-grey;
    font-size: rem(18px);
    line-height: rem(28px);
  }

  &-link {
    @include font('alternate', 'medium');
    display: inline-flex;
    align-items: center;
    transition: color .3s $ease-out-quad;
    color: $color-secondary;
    font-size: rem(16px);
    line-height: rem(32px);

    &:hover {
      color: $brand-black;

      .svg {
        transform: translateX(5px);
      }
    }

    .svg {
      @include margin(null null null 15px);
      transition: transform .3s $ease-out-quad;
      fill: currentColor;
    }
  }
}
