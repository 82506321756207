.filter-services {
  .button {
    @include margin(40px null null);
  }
}

.filters-services-label {
  @include margin(null 8px null null);
  @include font(alternate, bold);
  color: $brand-black;
  font-size: rem(40px);
  line-height: (44 / 40);

  @include mq($until: 'm') {
    font-size: rem(20px);
  }
}

.filters-services-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  + .filters-services-line {
    @include margin(24px null null);
  }
}

.filters-services-dropdown {
  position: relative;
  z-index: 10;

  &.is-open {
    z-index: 11;

    .filters-services-dropdown-list {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.filters-services-dropdown-toggle {
  @include font(alternate, bold);
  @include padding(0);
  transition: color .3s $ease-out-quart;
  border-bottom: 2px solid $color-secondary;
  outline: none;
  color: $color-secondary;
  font-size: rem(40px);
  line-height: (44 / 40);

  @include mq($until: 'm') {
    font-size: rem(20px);
  }

  &:hover {
    color: $brand-black;
  }

  .svg {
    display: inline-block;
    fill: currentColor;
    vertical-align: middle;
  }
}

.filters-services-dropdown-list {
  @include position(absolute, 100% null null -20px);
  @include size(auto, auto);
  @include padding(5px null);
  min-width: 100%;
  max-width: 400px;
  transition: opacity .3s $ease-out-quart;
  opacity: 0;
  background-color: $color-white;
  pointer-events: none;
}

.filters-services-dropdown-options {
  @include font(alternate, bold);
  @include padding(null 20px);
  transition: color .3s $ease-out-quart;
  color: $brand-black;
  font-size: rem(40px);
  line-height: (44 / 40);
  white-space: nowrap;
  cursor: pointer;

  @include mq($until: 'm') {
    font-size: rem(20px);
  }

  &.is-selected {
    display: none;
  }

  &:hover {
    color: $color-secondary;
  }
}
