// scss-lint:disable all
.post-header {
  &-picture {
    @include margin(null null 80px null);
    width: 100%;
    max-height: 480px;
    overflow: hidden;

    img {
      @include object-fit(cover);
      display: block;
      width: 100%;
      // Overwrite elementor css
      height: 100% !important; // scss-lint:disable ImportantRule
    }
  }
  &-wrap {
    @include margin(auto);
    max-width: 700px;
  }
  &-title {
    @include margin(null null 40px null);
    @include font('alternate', 'bold');
    color: $color-black;
    font-size: rem(52px);
    line-height: rem(58px);

    @include mq($until: 'm') {
      font-size: rem(36px);
      line-height: rem(38px);
    }
  }

  &-metas {
    @include font(sans-serif, bold);
    display: flex;
    flex-wrap: wrap;
    font-size: rem(12px);
    letter-spacing: 2px;
    line-height: rem(28px);
    text-decoration: none;
    text-transform: uppercase;
    color: $brand-grey-faded;

    & + & {
      @include margin(10px null null null);
    }

    @include mq($until: 'm') {
      flex-direction: column;
    }

    li:first-child::after {
      @include margin(null 10px null null);
      content: ":";
    }

    @include mq($from: 'm', $until: 'l') {
      &-item + &-item {
        margin-left: 20px;
      }
    }

    @include mq($from: 'l') {
      &-item + &-item::before {
        content: "|";
        @include margin(null 10px);
      }
    }

    a {
      color: $color-secondary;

      &:hover {
        color: $brand-black;
      }
    }
  }

  &-picture-placeholder {
    @include margin(null null 80px null);
    width: 100%;
    stroke: $color-white;
    background: $color-primary none;
    fill: $color-white;

    .svg {
      @include margin(0);
      display: block;
    }
  }

  &-content {
    @include margin(null 200px);

    @include mq($from: 'l') {
      margin-bottom: -20px;
    }

    @include mq($until: 'l') {
      @include margin(null 0 null);
    }

    &-title {
      @include margin(null null 30px null);
      @include font('alternate', 'bold');
      color: $color-black;
      font-size: rem(64px);
      line-height: rem(68px);

      @include mq($until: 'l') {
        font-size: rem(40px);
        line-height: rem(48px);
      }
    }

    &-sector {
      display: inline-block;
      color: $color-black;
      font-size: rem(12px);
      font-weight: bold;
      letter-spacing: 2px;
      line-height: rem(16px);
      text-transform: uppercase;

      &::after {
        @include margin(null 20px null 25px);
        content: '•';
        font-size: rem(12px);
        line-height: rem(16px);
      }
    }

    &-sectors {
      @include margin(null null 20px);

      li {
        display: inline-block;
        color: $color-black;
        font-size: rem(12px);
        font-weight: bold;
        line-height: rem(16px);
        text-transform: uppercase;

        a {
          color: color(success);

          &:hover {
            color: $brand-black;
          }
        }

        // Just needed
        // scss-lint:disable NestingDepth
        &::after {
          @include margin(null 20px null 25px);
          content: '•';
          font-size: rem(12px);
          line-height: rem(16px);
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }

    &-date {
      display: inline-block;
      color: $brand-grey-faded;
      font-size: rem(14px);
      line-height: rem(16px);
    }

    &-dates {
      @include margin(null null 20px null);

      .post-header-content-date {
        @include font('alternate', 'bold');
        display: inline-block;
        color: $color-black;
        font-size: rem(20px);
        line-height: rem(28px);
      }
    }

    &-status {
      @include margin(null 30px null null);
      @include font('alternate', 'bold');
      display: inline-block;
      color: color(error);
      font-size: rem(20px);
      line-height: rem(28px);
    }

    &-organization {
      @include font('alternate', 'semibold');
      color: $color-black;
      font-size: rem(16px);
      line-height: rem(28px);
    }
  }
}
