.slides-slider {
  position: relative;
  width: 100%;

  &.container {
    @include padding(null 0);

    @include mq($from: 'xxs') {
      width: 266px;
    }

    @include mq($from: 'xs') {
      width: 396px;
    }

    @include mq($from: 's') {
      width: 546px;
    }

    @include mq($from: 'm') {
      width: 714px;
    }

    @include mq($from: 'l') {
      width: 926px;
    }

    @include mq($from: 'xl') {
      width: 1166px;
    }

    @include mq($from: 'xxl') {
      width: 1166px;
    }
  }

  .tns-ovh {
    overflow: visible;
  }

  &-controls {
    .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include mq($until: 'l') {
        top: auto;
        bottom: 0;
      }
    }

    button:not([disabled]):hover {
      background-color: $color-secondary;
      fill: $color-white;
    }

    &-prev {
      position: absolute;
      z-index: 20;
      top: -57px;
      right: -60px;

      @include mq($until: 'l') {
        top: auto;
        right: auto;
        bottom: 0;
        left: 27px;
      }

      @include mq($from: 'xl') {
        right: -65px;
      }
    }

    &-next {
      position: absolute;
      z-index: 20;
      top: 7px;
      right: -60px;

      @include mq($until: 'l') {
        top: auto;
        right: 27px;
        bottom: 0;
      }

      @include mq($from: 'xl') {
        right: -65px;
      }
    }
  }
}
