// scss-lint:disable all
.service-list .block-listing {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
  @include margin(null null 60px null);
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: calc(50% - 27px);
    height: 1px;
    background: $brand-grey-clear;
  }
  &::after {
    left: 0;
    bottom: 0;
  }
}
.service-list-item {
  @include padding(20px null);
  position: relative;
  border-top: 1px solid $brand-grey-clear;
  width: calc(50% - 27px);

  @include mq($until: 'm') {
    width: 100%;
  }

  @include mq($from: 'm') {
    .cards > & {
      &:nth-child(even) {
        @include margin(null null null 54px);
      }
    }
  }

  &:last-child {
    border-bottom: 1px solid $brand-grey-clear;
  }

  &:hover {
    .service-item-title {
      color: $color-secondary;
    }
  }
}
.service-list-title, .service-item-title {
  @include font(alternate, bold);
  color: $brand-black;
  font-size: rem(24px);
  line-height: (32 / 24);
}
.service-list-heading {
  display: flex;
  justify-content: space-between;
}
.service-item-title {
  text-decoration: none;
  border-bottom: 2px solid $color-secondary;
}
.service-list-item {
  p {
    @include margin(10px null null null);
    color: $brand-grey;
    font-size: rem(18px);
    line-height: (28 / 18);
    @include mq($until: 'm') {
      width: 85%;
    }
  }
  .block-heading-link {
    position: relative;
    top: 8px;
    display: none;
    @include mq($until: 'm') {
      display: block;
    }
  }
  
}
