.back-link {
  @include padding(15px null null);

  a {
    color: $color-primary;
    text-decoration: none;

    .svg {
      margin-right: 5px;
      transition: transform .3s $ease-out-quart;
      fill: $color-primary;
      vertical-align: middle;
    }

    &:hover {
      .svg {
        transform: translateX(-5px);
      }
    }
  }

  + .section-wrapper {
    margin-top: 40px;
  }
}
