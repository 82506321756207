.block-filters {

  &-fields {
    @include margin(null -10px);
    display: flex;
    justify-content: space-between;

    @include mq($until: 'xl') {
      flex-direction: column;
    }

    > * {
      @include margin(null 10px);
      position: relative;
      width: 100%;

      @include mq($until: 'xl') {
        @include margin(null 0 10px);
      }

      input,
      select {
        @include padding(null 20px);
        @include font('sans-serif', 'regular');
        width: 100%;
        height: 56px;
        transition: border-color .3s $ease-out-quart;
        border: 1px solid $brand-grey-clear;
        border-radius: 0;
        outline: none;
        background: $color-white none;
        color: $color-black;
        font-size: rem(14px);

        appearance: none;

        // scss-lint:disable all
        &:focus {
          border-color: $brand-black;
        }
      }

      select {
        cursor: pointer;
        appearance: none;

        // scss-lint:disable all
        &::-ms-expand {
          display: none;
        }
      }

      .svg {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
        fill: $brand-grey-clear;
        pointer-events: none;
      }

      select + .svg {
        fill: $color-primary;
      }

      input + .svg {
        right: auto;
        left: 20px;
      }

      select:focus + .svg,
      input:focus + .svg {
        fill: $color-primary;
      }
    }

    .field-search {
      position: relative;

      input {
        @include padding(null null null 60px);
      }
    }
  }

  &-filtering {
    @include padding(25px 40px);
    background: $brand-grey-light none;

    label {
      @include padding(null null 20px);
      @include font('alternate', 'bold');
      display: block;
      font-size: rem(20px);
      line-height: rem(32px);
    }
  }

  &-sorting {
    @include padding(30px null null null);
    text-align: right;

    label {
      @include font('sans-serif', 'bold');
      @include margin(null 10px null null);
      color: $color-black;
      font-size: rem(12px);
      text-transform: uppercase;
    }

    .field-select {
      @include padding(null 25px null null);
      display: inline-block;
      position: relative;

      select {
        @include font('sans-serif', 'regular');
        width: 100%;
        border: 0;
        background: transparent none;
        color: $color-black;
        font-size: rem(14px);
        appearance: none;

        // scss-lint:disable all
        &::-ms-expand {
          display: none;
        }
      }

      .svg {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        fill: $color-primary;
        pointer-events: none;
      }
    }
  }

  &.aside {
    @include mq($from: 'xl') {
      width: 262px;
      float: right;

      .block-filters-filtering {
        @include padding(10px);
      }

      .block-filters-fields {
        @include margin(null 0);
        flex-direction: column;

        > * {
          @include margin(null 0 10px);
        }
      }

      + .block-listing {
        width: calc(100% - 310px);
      }
    }
  }

  &:not(.aside) {
    + .cards {
      @include margin(80px null null);
    }
  }
}
