// scss-lint:disable all
.services-search-container {
  background: $brand-grey-light;
  padding: 80px 47px;
  text-align: center;
  @include font(alternate, bold);
  color: $brand-black;
  font-size: rem(40px);
  line-height: (60 / 40);
  display: flex;
  flex-direction: column;
  @include mq($until: 'm') {
    font-size: rem(32px);
    line-height: (36 / 32);
    text-align: left;
    padding: 35px 47px;
  }
}
.services-search-container .section-link {
  @include margin(36px null null null);
}
.services-search-form {
  .select-wrapper {
    display: inline-flex;
    align-items: baseline;
    border-bottom: 2px solid $color-secondary;
    color: $color-secondary;
    position: relative;
    > span { font-size: rem(22px); }
  }
  .svg {fill: $color-secondary;}

  /** Override _select.scss **/
  .custom-select-opener {
    background: transparent;
    border: none;
    overflow: visible;
    font-size: rem(40px);
    color: $color-secondary;
    padding-right: 20px;
  }
  .custom-select-panel {
    font-size: rem(22px);
    top: calc(100% + 2px);
    left: 0;
    right: 0;
  }
  .is-open .custom-select-panel {
    border-color: $color-secondary; 
    border-width: 2px;
    z-index: 15;
  }

  @include mq($until: 'm') {
    label { display: block; }
    .select-wrapper > span {
      font-size: rem(16px);
      line-height: (20 / 16);
    }

    /** Override _select.scss **/
    .custom-select-opener { font-size: rem(32px); }
    .custom-select-panel { font-size: rem(16px); }

  }

  .custom-select-optgroup {
    &::before {
      display: none;
    }

    .custom-select-option {
      font-size: rem(16px);
      font-weight: 400;
      text-align: left;
      border-top: 1px solid rgba($brand-grey, .2);
    }

    div:first-child {
      padding-left: 20px;
      font-size: rem(18px);
      font-weight: 700;
    }
  }
}
.services-search-parameter + .services-search-parameter {
  @include margin(24px null null null);
}
