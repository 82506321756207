.button {
  @include font(sans-serif, bold);
  @include padding(12px 51px 11px);
  display: inline-block;
  transition: opacity .3s $ease-out-quart;
  border: 2px solid $color-secondary;
  border-radius: 26px 26px 0;
  background-color: $color-secondary;
  color: $color-white;
  font-size: rem(16px);
  line-height: (21 / 16);
  text-decoration: none;

  &:hover {
    opacity: .7;
  }

  &--reverse {
    border-color: $color-white;
    background-color: $color-white;
    color: $color-secondary;
  }

  &--ghost {
    background-color: rgba($color-white, 0);
    color: $color-secondary;
  }

  &[disabled],
  &--disable {
    border-color: lighten($brand-grey-faded, 30%);
    background-color: lighten($brand-grey-faded, 30%);
    color: $brand-grey-faded;
  }
}
