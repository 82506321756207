// Kennel Settings
// -----------------------------------------------------------------------------
$base-url: '../assets/';
$base-font-size: 16px;
$base-line-height: 2;


// Overrides Default Color Palette
// -----------------------------------------------------------------------------
$color-black: #000;
$color-white: #fff;
$color-blue: #00f;

$brand-black: #252929;
$brand-grey: #5c5f5f;
$brand-grey-faded: #929494;
$brand-grey-light: #f4f4f4;
$brand-grey-clear: #c8c9c9;

$default-colors: (
  text: $brand-grey,
  title: $brand-black,
  link: $color-blue,

  social-twitter: #04aceb,
  social-facebook: #3b5998,
  social-linkedin: #0085af,
  social-youtube: #cd201f,
  social-instagram: #e12f67,

  success: #00b2a9,
  warning: orange,
  error: #cd113f,
);


// Custom select colors
// -----------------------------------------------------------------------------
$custom-select-colors: (
  opener-frame: #fff,
  opener-caret: #000,
  opener-color: #000,
  opener-border: #000,
  panel-frame: #fff,
  panel-border: #000,
  optgroup-label-color: #000,
  optgroup-label-frame: #eee,
  optgroup-label-border: #ddd,
  option-color: #000,
  option-frame: #fff,
  option-border: #eee,
  option-focus-color: #000,
  option-focus-frame: #eee,
  option-focus-border: #ddd,
  option-selected-color: #000,
  option-selected-frame: #eee,
  option-selected-border: #ddd,
);


// Grid System
// -----------------------------------------------------------------------------
$gs-container: 1274px;
$gs-padding: 27px;


// Layers Manager
// -----------------------------------------------------------------------------
$z-layers: (
  above: 999,
  highest: 60,
  higher: 50,
  high: 40,
  low: 20,
  lower: 10,
  lowest: 0,
  below: -1,
);


$base-font-stack: (
  sans-serif: (
    light: (
      family: ('Roboto', sans-serif),
      weight: 300,
      style: normal,
      file: (
        family: 'Roboto',
        path: 'Roboto-Light',
        formats: (woff woff2)
      ),
    ),
    light-italic: (
      family: ('Roboto', sans-serif),
      weight: 300,
      style: italic,
      file: (
        family: 'Roboto',
        path: 'Roboto-LightItalic',
        formats: (woff woff2)
      ),
    ),
    regular: (
      family: ('Roboto', sans-serif),
      weight: 400,
      style: normal,
      file: (
        family: 'Roboto',
        path: 'Roboto-Regular',
        formats: (woff woff2)
      ),
    ),
    regular-italic: (
      family: ('Roboto', sans-serif),
      weight: 400,
      style: italic,
      file: (
        family: 'Roboto',
        path: 'Roboto-RegularItalic',
        formats: (woff woff2)
      ),
    ),
    medium: (
      family: ('Roboto', sans-serif),
      weight: 500,
      style: normal,
      file: (
        family: 'Roboto',
        path: 'Roboto-Medium',
        formats: (woff woff2)
      ),
    ),
    medium-italic: (
      family: ('Roboto', sans-serif),
      weight: 500,
      style: italic,
      file: (
        family: 'Roboto',
        path: 'Roboto-MediumItalic',
        formats: (woff woff2)
      ),
    ),
    bold: (
      family: ('Roboto', sans-serif),
      weight: 700,
      style: normal,
      file: (
        family: 'Roboto',
        path: 'Roboto-Bold',
        formats: (woff woff2)
      ),
    ),
    bold-italic: (
      family: ('Roboto', sans-serif),
      weight: 700,
      style: italic,
      file: (
        family: 'Roboto',
        path: 'Roboto-BoldItalic',
        formats: (woff woff2)
      ),
    ),
  ),
  alternate: (
    regular: (
      family: ('Archia', sans-serif),
      weight: 400,
      style: normal,
      file: (
        family: 'Archia',
        path: 'Archia-Regular',
        formats: (woff woff2)
      ),
    ),
    semibold: (
      family: ('Archia', sans-serif),
      weight: 600,
      style: normal,
      file: (
        family: 'Archia',
        path: 'Archia-SemiBold',
        formats: (woff woff2)
      ),
    ),
    bold: (
      family: ('Archia', sans-serif),
      weight: 700,
      style: normal,
      file: (
        family: 'Archia',
        path: 'Archia-Bold',
        formats: (woff woff2)
      ),
    ),
  ),
);


// Responsive Settings
// -----------------------------------------------------------------------------
$mq-responsive: true;
$mq-static-breakpoint: l;
$mq-show-breakpoints: (xxs, xs, s, m, l, xl, xxl);
