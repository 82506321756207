.team-card {
  display: flex;

  @include mq($until: 'm') {
    flex-direction: column;
    align-items: center;
  }
}

// Picture
// -----------------------------------------------------------------------------
.team-card-picture {
  @include size(80px);
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  @include mq($from: 'm') {
    + .team-card-content {
      @include padding(null null null 20px);
      width: calc(100% - 80px);
    }
  }

  .team-card-img {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    @include object-fit(cover);
  }
}

// Content
// -----------------------------------------------------------------------------
.team-card-content {
  @include padding(null null null 100px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @include mq($until: 'm') {
    @include padding(12px null null 0);
    align-items: center;
    text-align: center;
  }
}

.team-card-title {
  @include font(alternate, bold);
  color: $brand-black;
  font-size: rem(20px);
  line-height: (28 / 20);
}

.team-card-subtitle {
  @include margin(4px null null);
  max-width: 100%;
  color: $brand-grey;
  font-size: rem(14px);
  line-height: (16 / 14);
}

.team-card-excerpt {
  @include margin(22px null null);
  color: $brand-grey;
  font-size: rem(16px);
  line-height: (24 / 16);

  + .team-card-link {
    @include margin(12px null null);
  }
}

.team-card-link {
  @include margin(19px null null);
  @include font(sans-serif, medium);
  display: block;
  transition: color .3s $ease-out-quart;
  color: $brand-black;
  font-size: rem(16px);
  line-height: (19 / 16);
  text-decoration: none;

  &:hover {
    color: $color-secondary;
  }
}
