.listing-item {
  @include padding(40px null);
  position: relative;
  border-top: 1px solid $brand-grey-clear;

  &:last-child {
    border-bottom: 1px solid $brand-grey-clear;
  }

  &:hover {
    .listing-item-title {
      color: $color-secondary;
    }
  }
}

// Wrapper
// -----------------------------------------------------------------------------
.listing-item-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include mq($until: 'l') {
    flex-wrap: wrap;
  }
}

// Date
// -----------------------------------------------------------------------------
.listing-item-time {
  @include size(83px, auto);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  @include mq($until: 'l') {
    flex-direction: row;
    align-items: center;
    width: 100%;

    + .listing-item-content {
      @include margin(8px null null);
    }
  }

  @include mq($from: 'l') {
    + .listing-item-content:not(.listing-item-content--news) {
      width: 32%;
    }
  }

  .svg {
    @include margin(3px null 3px 5px);
    fill: $color-primary;

    @include mq($until: 'l') {
      @include margin(0 10px);
      transform: rotate(-90deg);
    }
  }
}

.listing-item-date {
  @include font(alternate, semibold);
  color: $brand-black;
  font-size: rem(16px);
  line-height: (28 / 16);
}

// Content
// -----------------------------------------------------------------------------
.listing-item-content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 40%;

  @include mq($until: 'l') {
    width: 100%;
  }
}

.listing-item-content--news {
  width: 77.6%;

  @include mq($until: 'l') {
    width: 100%;
  }
}

.elementor .listing-item-content--news--picture {
  align-items: flex-start;

  @include mq($until: 'l') {
    margin-top: 0;
  }

  &.no-picture {
    @include mq($until: 'l') {
      display: none;
    }
  }

  img {
    max-width: calc(100% - 45px);

    @include mq($until: 'l') {
      max-width: 100%;
    }
  }
}

.listing-item-content--news--picture:not(.no-picture) + .listing-item-content--news {
  @include mq($until: 'l') {
    margin-top: 45px;
  }
}

.listing-item-title {
  @include font(alternate, bold);
  transition: color .3s $ease-out-quart;
  color: $brand-black;
  font-size: rem(24px);
  line-height: (32 / 24);

  @include mq($until: 'l') {
    @include padding(null 30px null null);
    position: relative;
  }

  .svg {
    @include position(absolute, 5px 0 null null);
    fill: $color-secondary;

    @include mq($from: 'l') {
      display: none;
    }
  }
}

.listing-item-alert {
  @include font(alternate, semibold);
  color: color(error);
  font-size: rem(16px);
  line-height: (28 / 16);
}

.listing-item-subtitle {
  @include margin(12px null null);
  @include font(sans-serif, bold);
  color: $brand-black;
  font-size: rem(16px);
  line-height: (28 / 16);
}

.listing-item-excerpt {
  @include margin(20px null null);
  @include font(sans-serif, regular);
  color: $brand-grey;
  font-size: rem(18px);
  line-height: (28 / 18);
}

// Column
// -----------------------------------------------------------------------------
.listing-item-column {
  @include padding(4px null null);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-end;
  width: 15.4%;
  text-align: right;

  @include mq($until: 'l') {
    @include margin(45px null null);
    @include padding(0 null null);
    align-items: flex-start;
    width: calc(33.33% - 9px);
    text-align: left;
  }

  &.column-last {
    @include size(7%, auto);

    @include mq($until: 'l') {
      display: none;
    }
  }

  .svg {
    fill: $color-secondary;
  }
}

// Info
// -----------------------------------------------------------------------------
.listing-item-info {
  @include font(sans-serif, regular);
  color: $brand-black;
  font-size: rem(16px);
  line-height: (19 / 16);

  @include mq($until: 'l') {
    @include line-clamp(19px, 1, false);
  }
}

a.listing-item-info { // scss-lint:disable all
  position: relative;
  z-index: 2;
  transition: color .3s $ease-out-quart;

  &:hover {
    color: $color-secondary;
  }
}

// Link
// -----------------------------------------------------------------------------
.listing-item-link {
  @include position(absolute, 0 null null 0);
  @include size(100%);
  z-index: 1;
}
