
// Top
// -----------------------------------------------------------------------------
.footer-top {
  @include padding(20px null);
  display: flex;
  align-items: center;
  background-color: $brand-grey-light;

  @include mq($from: 'm') {
    min-height: 120px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq($until: 'm') {
      flex-direction: column;
    }
  }
}

// Logo
// -----------------------------------------------------------------------------
.footer-logo {
  display: block;

  img {
    display: block;
    max-width: 175px;
    max-height: 80px;
  }
}

// Socials
// -----------------------------------------------------------------------------
.footer-socials {
  display: flex;

  @include mq($until: 'm') {
    @include margin(28px null null);
  }
}

.footer-socials-item {
  + .footer-socials-item {
    @include padding(null null null 16px);
  }
}

.footer-socials-link {
  @include size(42px);
  display: block;
  position: relative;
  border: 2px solid $brand-black;
  border-radius: 22px;

  .svg {
    @include position(absolute, calc(50% - 20px) null null calc(50% - 20px));
    @include size(40px);
    fill: $brand-black;
  }
}

// Bottom
// -----------------------------------------------------------------------------
.footer-bottom {
  @include padding(20px null);
  background-color: $color-primary;

  @include mq($until: 'm') {
    @include padding(15px null 27px);
  }

  .container {
    display: flex;
    align-items: center;

    @include mq($until: 'l') {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

// Infos
// -----------------------------------------------------------------------------
.footer-infos {
  @include margin(null null null -32px);
  display: flex;

  @include mq($until: 'l') {
    flex-direction: column;
    align-items: flex-start;
  }
}

.footer-infos-item {
  position: relative;

  + .footer-infos-item {
    @include mq($from: 'm') {
      &::after {
        @include position(absolute, calc(50% - 8px) null null 0);
        @include size(1px, 16px);
        content: '';
      }
    }
  }
}

.footer-infos-value {
  @include padding(null 32px);
  display: block;
  font-size: rem(14px);
  line-height: 1;
  text-decoration: none;

  @include mq($until: 'l') {
    @include padding(8px null)
  }
}


// Nav
// -----------------------------------------------------------------------------
.footer-nav {
  @include margin(null null null auto);
  display: flex;

  @include mq($until: 'l') {
    @include margin(15px null null -20px);
    flex-direction: column;
  }
}

.footer-nav-link {
  @include padding(5px 20px);
  @include font(sans-serif, bold);
  display: block;

  @include mq($until: 'l') {
    + .footer-nav-link {
      @include margin(4px null null);
    }
  }
}


// Hub logo
// -----------------------------------------------------------------------------
.footer-logo-hub {
  @include margin(null null null 20px);
  display: block;

  @include mq($until: 'l') {
    @include margin(24px null null 0);
    align-self: center;
  }

  img {
    @include size(auto);
    display: block;
    max-width: 113px;
    max-height: 40px;
  }
}

// Theme
@if $theme == 'dark' {
  .footer-infos-item::after {
    background-color: $color-white;
  }

  .footer-infos-value {
    color: $color-white;
  }

  .footer-nav-link {
    color: $color-white;
  }

  .footer-logo-hub {
    .svg {
      fill: $color-white;
    }
  }
} @else {
  .footer-infos-item::after {
    background-color: $brand-black;
  }

  .footer-infos-value {
    color: $brand-black;
  }

  .footer-nav-link {
    color: $brand-black;
  }

  .footer-logo-hub {
    .svg {
      fill: $brand-black;
    }
  }
}
