.search-hero {
  @include padding(80px null 83px);
  background-color: $brand-grey-light;
}

// Form
// -----------------------------------------------------------------------------
.search-hero-form {
  position: relative;
  max-width: 706px;
}

// Label
// -----------------------------------------------------------------------------
.search-hero-label {
  @include font(sans-serif, bold);
  color: $brand-grey;
  font-size: rem(12px);
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase;
}

// Input
// -----------------------------------------------------------------------------
.search-hero-input {
  @include margin(25px null null);
  @include padding(5px 46px 15px null);
  @include font(alternate, bold);
  display: block;
  width: 100%;
  transition: border-color .3s $ease-out-quart;
  border: 0;
  border-bottom: 1px solid $brand-grey-faded;
  outline: none;
  background: 0;
  color: $brand-black;
  font-size: rem(40px);
  line-height: 1;

  &:focus {
    border-bottom-color: $color-secondary;
  }
}


// Submit
// -----------------------------------------------------------------------------
.search-hero-submit {
  @include position(absolute, null 0 0 null);
  @include size(46px, 63px);
  outline: none;

  .svg {
    transition: fill .3s $ease-out-quart;
    fill: $color-secondary;
  }

  &:hover {
    .svg {
      fill: $brand-black;
    }
  }
}
