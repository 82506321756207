.eb-map-container {
  width: 300px;

  h6 {
    @include padding(null null 15px);
    @include font('alternate', 'bold');
    color: $brand-black;
    font-size: rem(18px);
    line-height: rem(26px);
  }
}

.eb-map-content {
  .map-tooltip-address {
    @include margin(null null 20px);
    @include font('sans-serif', 'regular');
    color: $brand-grey;
    font-size: rem(16px);
    line-height: rem(20px);
  }

  .map-tooltip-contact {
    @include font('sans-serif', 'regular');
    color: $brand-black;
    font-size: rem(14px);
    line-height: rem(16px);
  }

  .map-tooltip-open-hours {
    @include margin(null null 20px);
    @include font('sans-serif', 'regular');
    color: $brand-grey-faded;
    font-size: rem(14px);
    line-height: rem(16px);
  }

  a {
    color: $brand-black;
  }
}
