// Default typography settings, to be included as soon as possible in the HTML
// 1. Make type rendering look crisper
// 2. The default font size is 16px
// 3. Prevent iOS and IE text size adjust after device orientation change, without disabling user zoom.
//
// @requires {variable} $base-font-size
html {
  @include font-smoothing; /* 1 */
  font-size: strip-units($base-font-size) / 16 * 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 3 */ // scss-lint:disable VendorPrefix
  -webkit-text-size-adjust: 100%; /* 3 */ // scss-lint:disable VendorPrefix
}


// Default text styles
// -----------------------------------------------------------------------------
body {
  @include font('sans-serif', 'regular');
  color: color(text);
  font-size: rem(14px);
  line-height: 2;
}

// Separator
hr {
  border-top: 1px solid $brand-grey-clear;
}

// Typeset
// -----------------------------------------------------------------------------
.typeset,
.elementor-text-editor {
  // Title
  // -----------------------------------------------------------------------------
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include font(alternate, bold);
    color: $brand-black;

    a {
      border-width: 2px;
      color: currentColor;
    }
  }

  h1 {
    font-size: rem(52px);
    line-height: (58 / 52);

    @include mq($until: 'm') {
      font-size: rem(36px);
      line-height: (38 / 36);

    }

    + * {
      @include margin(50px null null);
    }
  }

  h2 {
    font-size: rem(40px);
    line-height: (44 / 40);

    @include mq($until: 'm') {
      font-size: rem(28px);
    }

    + * {
      @include margin(40px null null);
    }
  }

  h3 {
    font-size: rem(24px);
    line-height: (32 / 24);

    + * {
      @include margin(40px null null);
    }

    + h1,
    + h2,
    + h3 {
      @include margin(40px null null);
    }
  }

  h4 {
    font-size: rem(18px);
    line-height: (26 / 18);

    + * {
      @include margin(40px null null);
    }
  }

  h5 {
    font-size: rem(18px);
    font-weight: 600;
    line-height: (26 / 18);

    + * {
      @include margin(20px null null);
    }
  }

  h6 {
    font-size: rem(16px);
    font-weight: 600;
    line-height: (28 / 16);

    + * {
      @include margin(20px null null);
    }
  }

  // Paragraph
  // -----------------------------------------------------------------------------
  p {
    color: $brand-grey;
    font-size: rem(18px);
    line-height: (28 / 18);

    @include mq($until: 's') {
      clear: both;
    }

    a {
      @include font(sans-serif, medium);
    }

    + * {
      @include margin(15px null null);
    }

    + ul,
    + ol {
      @include margin(25px null null);
    }

    + h1,
    + h2,
    + h3 {
      @include margin(40px null null);
    }
  }

  b,
  strong {
    font-weight: bold;
  }

  i,
  em {
    font-style: italic;
  }

  // Link
  // -----------------------------------------------------------------------------
  a {
    display: inline;
    transition: color .3s $ease-out-quart, border-color .3s $ease-out-quart;
    border-bottom: 1px solid $color-secondary;
    color: $color-secondary;
    text-decoration: none;

    &:hover {
      border-color: $brand-black;
      color: $brand-black;
    }
  }

  // List
  // -----------------------------------------------------------------------------
  ul,
  ol {
    li {
      position: relative;
      clear: both;
      color: $brand-grey;
      font-size: rem(18px);
      line-height: (28 / 18);

      + li {
        @include margin(10px null null);
      }

      .p1 {
        @include margin(10px null null);
      }

      // Needed to overwrite wysiwyg
      .s1 b { // scss-lint:disable SelectorDepth
        @include margin(20px null null);
        display: block;
        color: $color-black;
        font-size: rem(20px);
        font-weight: 700;
        line-height: rem(28px);
      }
    }

    + * {
      @include margin(25px null null);
    }

    + h1,
    + h2,
    + h3 {
      @include margin(40px null null);
    }
  }

  ul {
    > li {
      @include padding(null null null 20px);

      &::before {
        @include position(absolute, 12px null null 0);
        @include size(6px);
        content: '';
        border-radius: 3px;
        background-color: $color-primary;
      }

      li::before {
        @include font('sans-serif', 'regular');
        background-color: $brand-grey;
      }
    }
  }

  ol {
    counter-reset: ol;

    > li {
      @include padding(null null null 30px);
      counter-increment: ol;

      &::before {
        @include position(absolute, 3px null null 0);
        @include font(alternate, 'semibold');
        content: counter(ol);
        color: $color-primary;
        font-size: rem(16px);
      }

      li::before {
        @include font('sans-serif', 'regular');
        color: $brand-grey;
      }
    }
  }

  // Blockquote
  // -----------------------------------------------------------------------------
  blockquote {
    p {
      @include font(alternate, semibold);
      color: $brand-black;
      font-size: rem(18px);
      line-height: (32 / 18);

      &::before {
        content: '« ';
        display: inline;
      }

      &::after {
        content: ' »';
        display: inline;
        position: absolute;
      }
    }

    + * {
      @include margin(15px null null);
    }

    + ul,
    + ol {
      @include margin(25px null null);
    }

    + h1,
    + h2,
    + h3 {
      @include margin(40px null null);
    }
  }

  // Images
  img {
    max-width: 100%;
    height: auto;

    &.alignleft {
      float: left;

      @include mq($from: 's') {
        margin-right: 30px;
      }
    }

    &.alignright {
      float: right;

      @include mq($from: 's') {
        margin-left: 30px;
      }
    }

    &.aligncenter {
      @include margin(null auto);
      display: block;
    }
  }

  p img {
    @include mq($until: 's') {
      &.alignleft,
      &.alignright {
        @include margin(15px null 25px);
      }
    }

    &.aligncenter {
      @include margin(25px null);
    }
  }

  figure {
    + * {
      @include margin(50px null null);
    }

    + p {
      @include margin(40px null null);
    }

    figcaption {
      font-size: rem(14px);
      font-weight: 600;
      line-height: 1.4;
    }
  }
}
