.elementor-widget-team-members,
.elementor-widget-team-members-selection {
  .block-listing {
    display: flex;
    flex-wrap: wrap;

    @include mq($until: 'm') {
      flex-direction: column;
    }

    .team-card {
      flex-shrink: 0;
      width: 100%;

      @include mq($until: 'm') {
        + .team-card {
          @include margin(40px null null);
        }
      }

      @include mq($from: 'm', $until: 'l') {
        width: calc(50% - 10px);

        &:nth-child(even) {
          @include margin(null null null 20px);
        }

        &:nth-child(n + 3) {
          @include margin(40px null null);
        }
      }

      @include mq($from: 'l') {
        width: calc(33.33% - 14px);

        &:nth-child(3n - 1) {
          @include margin(null 20px);
        }

        &:nth-child(n + 4) {
          @include margin(40px null null);
        }
      }
    }
  }
}

.block-listing {
  + .button.team-button {
    margin-top: 50px;
  }
}
