.section-image-text {
  display: flex;
  justify-content: space-between;

  @include mq($until: 'm') {
    flex-direction: column-reverse;
  }

  &[data-alignment="left"] {
    @include mq($from: 'm') {
      flex-direction: row-reverse;
    }
  }

  .image-text-content {
    @include margin(0 null 40px);
    max-width: calc(50% - 104px);

    @include mq($until: 'm') {
      @include margin(40px null 0);
      max-width: 100%;
    }

    .section-title {
      @include margin(null null 15px);
      @include font('alternate', 'bold');
      color: $color-black;
      font-size: rem(40px);
      line-height: rem(44px);
    }

    .section-subtitle {
      @include margin(null null 40px);
      @include font('alternate', 'semibold');
      display: block;
      color: $color-black;
      font-size: rem(18px);
      line-height: rem(26px);
    }

    .section-text {
      @include font('sans-serif', 'regular');
      color: $brand-grey;
      font-size: rem(18px);
      line-height: rem(26px);
    }

    .section-link {
      @include margin(50px null null);
    }
  }

  .image-text-picture {
    @include aspect-ratio(620, 408);
    @include size(620px, auto);
    flex-shrink: 0;
    max-width: calc(50%);

    @include mq($until: 'm') {
      width: 100%;
      max-width: 100%;
    }

    img {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      @include object-fit(cover);
    }
  }
}
