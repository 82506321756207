.content-table {
  overflow-x: auto;

  @include mq($from: 'l') {
    width: 100%;
  }

  @include mq($until: 'l') {
    @include margin(null -27px);
    display: flex;

    &::before,
    &::after {
      @include size(27px, 1px);
      content: '';
      flex-shrink: 0;
    }
  }

  table {
    @include mq($from: 'l') {
      min-width: 100%;
    }
  }

  tr {
    border-bottom: 1px solid $brand-grey-clear;

    &:nth-child(even) {
      background-color: $brand-grey-light;
    }
  }

  th {
    @include padding(17px 10px);
    min-width: 160px;
    color: $brand-grey;
    font-size: rem(14px);
    line-height: 1.2;
    text-align: left;

    p,
    a {
      display: inline-block;
    }

    &:first-child {
      @include padding(null null null 16px);
    }

    &:last-child {
      @include padding(null 16px null null);
    }
  }

  td {
    @include padding(14px 10px 13px);
    min-width: 160px;
    color: $brand-black;
    font-size: rem(16px);
    line-height: (28 / 16);

    &:first-child {
      @include padding(null null null 16px);
    }

    &:last-child {
      @include padding(null 16px null null);
    }

    strong {
      @include font(alternate, semibold);
    }

    a {
      color: $color-secondary;
    }

    p,
    a {
      display: inline-block;
    }

    .tooltip {
      @include margin(-3px null null 4px);
    }
  }
}
