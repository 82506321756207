.tooltip {
  @include font(sans-serif, bold);
  @include padding(0);
  @include size(14px);
  display: inline-block;
  position: relative;
  border-radius: 7px;
  outline: none;
  background-color: $brand-grey;
  color: $color-white;
  font-size: rem(11px);
  line-height: 14px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;

  &--up {
    .tooltip-dropdown {
      @include padding(10px 10px 36px);
      top: auto;
      bottom: 0;
      background-image: linear-gradient(to top, rgba($brand-grey, 0) 0, rgba($brand-grey, 0) 26px, $brand-grey 27px, $brand-grey 100%);

      &::before {
        @include triangle(12px, $brand-grey, 'down');
        @include position(absolute, auto null 21px null);
        border-bottom: 0;
      }
    }
  }
}

.tooltip-dropdown {
  @include position(absolute, 0 null null -1px);
  @include font(sans-serif, regular);
  @include padding(36px 10px 10px);
  display: none;
  z-index: 1;
  width: 126px;
  background-image: linear-gradient(to bottom, rgba($brand-grey, 0) 0, rgba($brand-grey, 0) 26px, $brand-grey 27px, $brand-grey 100%);
  color: $color-white;
  font-size: rem(12px);
  line-height: (14 / 12);
  text-align: left;
  cursor: default;

  &::before {
    @include triangle(12px, $brand-grey, up);
    @include position(absolute, 21px null null 2px);
    content: '';
  }

  .has-dropdown & {
    display: block;
  }
}
