// Global
// -----------------------------------------------------------------------------
.sibling-menu {
  @include mq($until: 'l') {
    position: relative;

    &::after {
      @include triangle(14px, $color-secondary, down);
      @include position(absolute, 26px 0 null null);
      content: '';
      pointer-events: none;
    }
  }

  .sibling-menu-nav {
    @include mq($until: 'l') {
      display: none;
    }
  }

  .sibling-menu-list {
    @include margin(null -15px);
    display: flex;
    flex-wrap: wrap;
  }

  .sibling-menu-item {
    flex-shrink: 0;
  }

  .sibling-menu-link {
    transition: color .3s $ease-out-quart;

    &:hover {
      color: $color-secondary;
    }

    &.is-active {
      color: $color-secondary;
      font-weight: 500;

      .link-text::after {
        transform: scaleX(1);
      }
    }
  }
}

// Default theme
// -----------------------------------------------------------------------------
.sibling-menu-nav-default {
  border-bottom: 1px solid rgba($brand-grey-faded, .33);

  .sibling-menu-link {
    @include padding(10px 15px 0);
    display: block;
    outline: none;
    color: $brand-black;
    font-size: rem(14px);
    line-height: 1;

    .link-text {
      @include padding(null null 10px);
      display: block;
      position: relative;

      &::after {
        @include position(absolute, null null -1px 0);
        @include size(100%, 1px);
        content: '';
        display: block;
        transform: scaleX(0);
        transition: transform .3s $ease-out-quart;
        background-color: $color-secondary;
      }
    }
  }
}

// Alternate theme
// -----------------------------------------------------------------------------
.sibling-menu-nav-alternate {
  @include padding(28px null);
  position: relative;

  // Background
  &::before {
    @include position(absolute, 0 50% null null);
    @include size(100vw, 100%);
    content: '';
    z-index: 0;
    transform: translateX(50%);
    background-color: $brand-grey-light;
  }

  .sibling-menu-list {
    position: relative;
    z-index: 1;
    flex-wrap: wrap;
  }

  .sibling-menu-link {
    @include padding(15px 15px);
    display: block;
    color: $brand-black;
    font-size: rem(14px);
    line-height: 1;
  }
}


// Mobile
.sibling-menu-select {
  @include padding(19px 54px 18px 27px);
  @include margin(null null null -27px);
  display: block;
  width: calc(100% + 54px);
  border: 0;
  border-radius: 0;
  outline: none;
  background-color: $brand-grey-light;
  font-size: rem(14px);
  line-height: 1.4;
  appearance: none;

  @include mq($from: 'l') {
    display: none;
  }
}
