.gallery-grid {
  @include margin(-10px);

  .grid-sizer {
    width: calc(100% / 3 - 20px);

    @include mq($until: 'm') {
      width: 100%;
    }
  }
}
