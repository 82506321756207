@charset 'UTF-8';

/// Truncate multiple line text
/// Need a polyfil for some browsers
///
/// @example scss - Usage
///   .element {
///     @include line-clamp(4, 22px);
///   }
///
/// @example css - CSS Output
///   .element {
///     height: 88px;
///     overflow: hidden;
///     
///     @supports (-webkit-line-clamp: 4) {
///       display: -webkit-box;
///       -webkit-box-orient: vertical;
///       -webkit-line-clamp: 4;
///       text-overflow: ellipsis;
///     }
///   }

@mixin line-clamp($lines, $line-height, $fixed: true) {
  @if $fixed {
    height: $lines * $line-height;
  } @else {
    max-height: $lines * $line-height;
  }
  overflow: hidden;

  @supports (-webkit-line-clamp: $lines) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    text-overflow: ellipsis;
  }
}
