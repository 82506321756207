// Set up a decent box model on the root element
// -----------------------------------------------------------------------------
html {
  box-sizing: border-box;
  height: 100%;
}


// Make all elements from the DOM inherit from the parent box-sizing
// Since `*` has a specificity of 0, it does not override the `html` value
// making all elements inheriting from the root box-sizing value
// See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}


// Set up body size to fill screen
// -----------------------------------------------------------------------------
body {
  @include size(100%, auto);
  @include font(sans-serif, regular);
  color: color(text);
  font-size: 18px;
  line-height: (28 / 18);

  &.has-menu,
  &.has-search,
  &.has-menu-faitiere {
    @include size(100%);
    overflow: hidden;
  }
}


// Set up site container to remove any overflow content
// -----------------------------------------------------------------------------
.site-container {
  overflow: hidden;
}


// Set up global SVG container
// -----------------------------------------------------------------------------
.svg {
  display: inline-block;

  svg {
    display: block;
  }
}


.container {
  @include padding(null 27px);
  @include margin(null auto);
  width: 100%;
  max-width: $gs-container;
}

.section-wrapper,
.elementor-section-wrap > section {
  @include margin(50px null);

  @include mq($until: 'l') {
    @include margin(70px null);
  }

  &:first-child {
    @include margin(40px null null);

    .elementor-widget-image-with-text {
      @include mq($from: 'l') {
        @include margin(40px null null);
      }
    }

    .banner-header {
      @include margin(null null 50px);
    }
  }

  &:last-child {
    @include margin(null null 40px);
  }

  .elementor-widget + .elementor-widget:not(.elementor-widget-breadcrumb) {
    @include margin(100px null null);

    @include mq($until: 'm') {
      @include margin(50px null null);
    }

    @include mq($until: 'l') {
      @include margin(70px null null);
    }
  }

  .elementor-widget-breadcrumb + .elementor-widget {
    // Force disable margin after breadcrump widget
    margin-top: 0 !important; // scss-lint:disable ImportantRule
  }
}
