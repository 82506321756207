.site-search {
  @include position(fixed, 0 null null 0);
  @include size(100%);
  display: none;
  z-index: 1000;

  .has-search & {
    display: block;
  }

  .container {
    position: relative;
  }
}

// Form
// -----------------------------------------------------------------------------
.site-search-form {
  @include position(absolute, 50% 50% null null);
  @include padding(50px 27px);
  z-index: 1;
  width: 100%;
  max-width: 1064px;
  transform: translate(50%, -50%);
}

.site-search-label {
  @include font(sans-serif, bold);
  color: $color-white;
  font-size: rem(12px);
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase;
}

.site-search-input {
  @include margin(25px null null);
  @include padding(5px 46px 15px null);
  @include font(alternate, bold);
  display: block;
  width: 100%;
  border: 0;
  border-bottom: 1px solid $brand-grey-faded;
  outline: none;
  background: 0;
  color: $color-white;
  font-size: rem(40px);
  line-height: 1;

  @include mq($until: 'l') {
    font-size: rem(24px);
  }

  @include mq($until: 321px) {
    font-size: rem(18px);
  }

  &::placeholder {
    color: $brand-grey-faded;
  }

  &:focus {
    border-color: $color-white;
  }
}

.site-search-submit {
  @include position(absolute, null 18px 50px null);
  @include size(46px, 63px);
  transition: fill .3s $ease-out-quart;
  outline: none;

  .svg {
    fill: $color-white;
  }

  &:hover {
    .svg {
      fill: $color-secondary;
    }
  }
}

// Close
// -----------------------------------------------------------------------------
.site-search-close {
  @include position(absolute, 11px 12px null null);
  @include size(24px);
  z-index: 3;
  outline: none;

  @include mq($until: 'l') {
    top: 28px;
    right: 27px;
  }

  .svg {
    @include position(absolute, 50% null null 50%);
    transform: translate(-50%, -50%);
    transition: fill .3s $ease-out-quart;
    fill: $color-white;
  }

  &:hover {
    .svg {
      fill: $color-secondary;
    }
  }
}

// Backdrop
// -----------------------------------------------------------------------------
.site-search-backdrop {
  @include position(absolute, 0 null null 0);
  @include size(100%);
  z-index: 0;
  background: $brand-black;
}
