// Element's override
html body {
  // scss-lint:disable all
  .elementor-section.elementor-section-boxed>.elementor-container {
    @include padding(null 27px);
    max-width: $gs-container;
  }

  .elementor-column-gap-wide>.elementor-row>.elementor-column>.elementor-element-populated {
    @include padding(0 null);
  }

  .elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
    @include padding(0);
  }
}
