[template="404"] {
  position: relative;
  min-height: calc(100vh - 160px);
  background-color: $color-primary;
  background-size: cover;

  @include mq($until: 'l') {
    min-height: calc(100vh - 80px);
    background-position: 50% 50%;
  }

  .template-404-wrapper {
    @include position(absolute, 50% 50% null null);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 629px;
    transform: translate(50%, -50%);
  }

  .template-404-title {
    @include font(alternate, bold);
    font-size: rem(150px);
    line-height: 1;

    @include mq($until: 'l') {
      font-size: rem(120px);
    }
  }

  .template-404-text {
    @include margin(25px null null);
    @include font(alternate, bold);
    font-size: rem(24px);
    line-height: (32 / 24);
    text-align: center;
  }

  .template-404-cta {
    @include margin(36px null null);
    @include font(sans-serif, medium);
    color: currentColor;
    font-size: rem(16px);
    line-height: 2;
    text-decoration: none;

    &:hover {
      .svg {
        transform: translateX(5px);
      }
    }

    .svg {
      @include margin(null null null 12px);
      transition: transform .3s $ease-out-quart;
      fill: currentColor;
      vertical-align: middle;
    }
  }

  // Theme
  @if $theme == 'dark' {
    background-image: image('backgrounds/404.png');

    .template-404-wrapper {
      color: $color-white;
    }
  } @else {
    background-image: image('backgrounds/404-light.png');

    .template-404-wrapper {
      color: $brand-black;
    }
  }
}
