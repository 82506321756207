// scss-lint:disable all
.card {
  display: flex;
  position: relative;
  flex-direction: column;

  &:hover {
    .card-picture .card-image {
      transform: scale(1.15);
    }

    .card-title {
      color: $color-secondary;
    }

    .card-button {
      .svg {
        transform: translateX(5px);
      }
    }
  }

  .card-wrapper {
    @include size(100%);
    display: flex;
    flex-direction: column;
  }

  // Picture
  // -----------------------------------------------------------------------------
  .card-picture {
    @include aspect-ratio(392, 280);
    width: 100%;
    overflow: hidden;
    background-color: $color-primary;

    .card-image {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      @include object-fit(cover);
      transition: transform .3s $ease-out-quart;
    }

    .svg {
      display: block;

      svg {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        fill: rgba($color-white, .4);
      }
    }
    &.card-picture--caption {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-decoration: none;

      figcaption {
        @include font(alternate, bold);
        padding: 20px;
        color: white;
        font-size: rem(24px);
        line-height: rem(32px);

        span {
          position: relative;
          z-index: 1;
          text-decoration: none;
        }

        &::before {
          content: "";
          z-index: 1;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba($color-primary, .8);
        }

      }

      &:hover {
        figcaption { 
          span { border-bottom: 2px solid $color-white; }
          &::before { background: transparent; }
        }
      }
      
    }
  }

  // Content
  // -----------------------------------------------------------------------------
  .card-content {
    @include padding(20px null null);
    display: flex;
    flex-direction: column;
  }
  @at-root .reverse .card .card-content {
    background: $color-white;
    @include padding(40px);
  }

  .card-type {
    @include font(sans-serif, bold);
    @include padding(10px null null);
    color: $brand-black;
    font-size: rem(12px);
    letter-spacing: 2px;
    line-height: (16 / 12);
    text-decoration: none;
    text-transform: uppercase;

    + .card-date {
      @include margin(28px null null);
    }

    + .card-title {
      @include margin(12px null null);

      @include mq($from: 'm') {
        [data-cols="2"] & {
          @include margin(15px null null);
        }
      }
    }
  }

  // scss-lint:disable all
  a.card-type  {
    position: relative;
    z-index: 10;
    transition: color .3s $ease-out-quart;
    color: $color-secondary;

    &:hover {
      color: $brand-black;
    }
  }

  // Date
  .card-date {
    @include padding(null null null 28px);
    @include font(alternate, semibold);
    position: relative;
    color: $brand-black;
    font-size: rem(16px);
    line-height: (28 / 16);

    .svg {
      @include position(absolute, 6px null null 0);
      fill: $color-primary;
    }

    + .card-title {
      @include margin(19px null null);
    }
  }

  // Title
  .card-title {
    @include line-clamp(3, 32px, false);
    @include font(alternate, bold);
    transition: color .3s $ease-out-quart;
    color: $brand-black;
    font-size: rem(24px);
    line-height: (32 / 24);

    @include mq($from: 'm') {
      [data-cols="2"] & {
        @include line-clamp(3, 44px, false);
        font-size: rem(40px);
        line-height: (44 / 40);
      }

      [data-cols="4"] & {
        @include line-clamp(3, 28px, false);
        font-size: rem(20px);
        line-height: (28 / 20);
      }
    }
  }

  .card-title-underline {
    border-bottom: 2px solid $color-secondary;
  }

  // Excerpt
  .card-excerpt {
    @include margin(16px null null);
    @include font(sans-serif, regular);
    @include line-clamp(4, 28px, false);
    color: $brand-grey;
    font-size: rem(18px);
    line-height: (28 / 18);

    @include mq($from: 'm') {
      [data-cols="2"] & {
        @include margin(21px null null);
      }
    }
  }

  // Posted
  .card-posted {
    @include margin(28px null null);
    color: $brand-grey-faded;
    font-size: rem(14px);
    line-height: (16 / 14);

    &:not(:last-child) {
      @include margin(null null 20px);
    }
  }

  // Button
  .card-button {
    @include font(sans-serif, medium);
    @include margin(auto null null);
    color: $color-secondary;
    font-size: rem(16px);
    line-height: 2;

    .svg {
      @include margin(-1px null null 16px);
      transition: transform .3s $ease-out-quart;
      fill: currentColor;
      vertical-align: middle;
    }
  }

  // Link
  // -----------------------------------------------------------------------------
  .card-link {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    z-index: 2;
  }
}
