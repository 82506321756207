.slider {

  &-controls {
    button {
      display: inline-block;
      width: 46px;
      height: 46px;
      transition: fill .3s $ease-out-quart, background-color .3s $ease-out-quart;
      border: 2px solid $color-secondary;
      border-radius: 50%;
      outline: none;
      fill: $color-secondary;
      text-align: center;

      > span {
        line-height: 46px;
      }

      &[disabled] {
        border-color: $brand-grey-faded;
        fill: $brand-grey-faded;
      }
    }
  }

  &-pagination {
    @include padding(40px null null null);
    text-align: center;

    > button {
      @include padding(6px);
      outline: none;

      &::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $brand-grey-faded none;
      }

      &.tns-nav-active {
        // Needed to overwrite TinySlider
        // scss-lint:disable NestingDepth
        &::before {
          background-color: $color-secondary;
        }
      }
    }
  }

  &:not(.slides-slider) {
    .slider-controls {
      button:not([disabled]):hover {
        fill: $color-black;
      }
    }
  }
}

.selection-slider,
.testimonial-slider {
  .tns-ovh {
    overflow: visible;
  }
}

