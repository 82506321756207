.banner-header {
  display: flex;
  position: relative;
  justify-content: space-between;

  &-content {
    position: relative;
    align-self: center;
    width: 40%;
    max-width: 492px;

    @include mq($until: 'l') {
      width: calc(100% -  40px);
    }

    @include mq($from: 'l', $until: 'xl') {
      padding-right: 30px;
    }

    @include mq($from: 'xl') {
      @include padding(null 22px null null);
    }
  }

  &-picture {
    position: relative;

    @include mq($from: 'l') {
      @include aspect-ratio(600, 480);
      width: 49.18%;
      max-width: 600px;
    }

    @include mq($until: 'l') {
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(100% - 10px);
      width: 300px;
      height: 100%;
    }

    .picture {
      position: relative;
      z-index: 10;

      @include mq($from: 'l') {
        @include position(absolute, 0 null null 0);
        @include size(100%);
      }

      @include mq($until: 'l') {
        visibility: hidden;
      }

      img {
        display: block;

        @include mq($from: 'l') {
          @include position(absolute, 0 null null 0);
          @include object-fit(cover);
          @include size(100%);
        }
      }
    }

    .deco {
      position: absolute;
      z-index: 5;
      top: 38px;
      left: -130px;
      width: 100%;
      height: 100%;
      background: $color-primary;

      @include mq($from: 'l') {
        @include aspect-ratio(624, 437);
        @include position(absolute, auto null -40px -128px);
        @include size(100%, auto);

        @include mq($until: 'xl') {
          left: -100px;
        }
      }

      @include mq($until: 'l') {
        top: 0;
        left: 0;
      }

      .svg {
        @include mq($from: 'l') {
          @include position(absolute, 0 null null 0);
          @include size(100%);
        }

        // scss-lint:disable all
        svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          fill: rgba($color-white, .4);
        }
      }
    }

    &.no-picture {
      .picture-placeholder {
        @include position(absolute, 0 null null 0);
        @include size(100%, auto);
        padding-bottom: 66%;
        background: $color-secondary;
      }
    }
  }

  &-title {
    @include padding(null null 30px null);
    @include font('alternate', 'bold');
    color: $color-black;
    font-size: rem(52px);
    line-height: rem(58px);
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;

    @include mq($until: 'l') {
      font-size: rem(36px);
      line-height: rem(38px);
    }
  }

  &-text {
    @include padding(null null 70px null);
    @include font('sans-serif', 'bold');
    color: $color-black;
    font-size: rem(18px);
    line-height: rem(26px);

    @include mq($until: 'l') {
      font-size: rem(16px);
      line-height: rem(24px);
    }

    p + p {
      @include margin(10px null null);
    }

    p {
      @include font('sans-serif', 'bold');
      color: $color-black;
      font-size: rem(18px);
      line-height: rem(26px);

      @include mq($until: 'l') {
        font-size: rem(16px);
        line-height: rem(24px);
      }

      a {
        @include font('sans-serif', 'bold');
      }

      i,
      em,
      b,
      strong {
        @include font('sans-serif', 'bold', family);
      }
    }

    b,
    strong {
      font-weight: bold;

      a {
        font-weight: bold;
      }
    }

    i,
    em {
      font-style: italic;
    }

    a {
      transition: color .3s $ease-out-quart, border-color .3s $ease-out-quart;
      border-bottom: 1px solid $color-secondary;
      color: $color-secondary;
      text-decoration: none;

      &:hover {
        border-color: $brand-black;
        color: $brand-black;
      }
    }
  }

  .section-wrapper:first-child .container & {
    @include margin(null null 100px);
  }
}
